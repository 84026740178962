import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/Seo"
import Hero from "../components/Hero/Hero"
import Breadcrumbs from "../components/Breadcrumbs"
import RichText from "../components/RichText"
import Image from "../components/Image/Image"

import { getPlainText, getPrettyURL } from "../utils"

import { AuthorModel } from "../models/pages"
import { SeoDataModel } from "../models/common"
import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

export const AuthorPage: React.FC<AuthorModel> = props => {
  // page data
  const _page =  get(props, "data.page") as any as AuthorModel;
  _page.pageContext = props.pageContext
  const [page, setPage] = useState(_page);

  useEffect(() => {
    contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext))
}, []) 

  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  const navLabel = getPlainText(page.nav_label)

  // SEO Fields
  const seoData: SeoDataModel = {
    metaTitle: page.seo?.title,
    metaDescription: page.seo?.description,
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    pageContext: props.pageContext,
  }

  return (
    <div className="authorPage">
      <Seo {...seoData} />

      {/* Breadcrumbs */}
      <Breadcrumbs
        currentPage={navLabel || heroHeading}
        pageContext={props.pageContext}
      />

      {/* Hero */}
      <section className="hero">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                <Hero heading={heroHeading} pageContext={props.pageContext} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content */}
      <section>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                {page.image && (page.image?.dimension?.width || 0) > 240 && (
                  <Image
                    image={page.image}
                    cssClass="author__image"
                    objectFit="scale-down"
                  />
                )}
                <RichText
                  content={page.content}
                  cssClass="content"
                  pageContext={props.pageContext}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

AuthorPage.defaultProps = defaultProps

export default AuthorPage

/****
	Data
****/
export const query = graphql`
  query AuthorPageByID($pageId: String!) {
    page: contentstackAuthor(id: { eq: $pageId }) {
      __typename
      uid
      ...AuthorFields
      image {
        ...CmsImageFields
        gatsbyImageData(width: 240, quality: 95)
      }
      qualification
      role
      content
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }
  }

  fragment AuthorFields on Contentstack_author {
    id
    url
    hero {
      heading
    }
    mini_img: image {
      ...CmsImageFields
      gatsbyImageData(width: 70, quality: 95)
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`
